import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  && {
    border-radius: 50px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    // border: none;
    // :hover {
    //   border: none;
    // }
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0.05em;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
