import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledButton } from './style';

const RoundedButton = ({ variant, color, children, loading, ...props }) => (
  <StyledButton
    variant={variant === 'text' ? undefined : variant}
    color={color}
    disabled={loading}
    startIcon={loading && <CircularProgress size={20} />}
    {...props}
  >
    {children}
  </StyledButton>
);

RoundedButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
};

RoundedButton.defaultProps = {
  children: undefined,
  variant: 'contained',
  // block: false,
  color: undefined,
  loading: false,
};

export default RoundedButton;