/* eslint-disable import/named */
import { useQuery, useMutation } from '@apollo/client';
import { UPGRADE_STATUS, USER_QUERY } from '@app/apollo/queries';
import { useTranslation } from '@app/config/i18n';
import { isOrganiser, redirectToRoute, setAuthCookie } from '@app/helpers';
import Link from '@atoms/Link';
import NextLink from 'next/link';
import {
  Button as MuiButton,
  Box,
  IconButton,
  MenuItem,
  Toolbar,
  Menu,
  Grid,
  Badge,
  Hidden,
} from '@mui/material';
import CustomDialog from '@app/components/molecules/CustomDialog';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Image from '@atoms/Image';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import _ from 'lodash';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { CREATE_SCIENTIST_PROFILE } from '@app/apollo/mutations';
import MutationsStatus from '@app/components/molecules/MutationsStatus';
import Typography from '@app/components/atoms/Typography';
import Spacer from '@atoms/Spacer';
import { MessageOutlined } from '@mui/icons-material';
import { useStateContext } from '@app/utils/state';
import Cookies from 'js-cookie';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, LogoContainer, UserAvatar, MyDivider } from './style';
// import CookieAlert from '@molecules/common/CookieAlert';

const Logo = '/images/sv_logo_2.png';
const LogoAfterLogin = '/images/LogoAfterLogin.png';
const SmallLogoAfterLogin = '/images/SmallLogoAfterLogin.png';

const Header = ({ sidebar, onClick, hasElevation, linkedInUser }) => {
  const [scientistModal, setScientistModal] = useState(false);
  const [refetchScientist, setRefetchSCientist] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [auth, setAuth] = useState(false);
  // const [showCookieAlert, setShowCookieAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['common', 'applications', 'landing']);
  // const { user } = useUser();

  const { data, refetch } = useQuery(USER_QUERY);

  const user = data?.me;

  const scientistProfile = {
    name: user && user.name,
    profile_image: null,
    email: user && user.email,
    phone: '',
    field: '',
    degree: null,
    university: '',
    location: null,
    employer: '',
    website: '',
    social_links: [],
    expertise_areas: [],
    skills: [],
    research_interests: [],
    projects: [],
    publications: [],
  };

  const { data: { upgradeStatus } = {} } = useQuery(UPGRADE_STATUS);

  const open = Boolean(anchorEl);

  const router = useRouter();

  if (!auth && user) {
    setAuth(true);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToProfile = () => {
    redirectToRoute({}, 'update_profile');
  };

  const redirectToUpgradeAcount = () => {
    redirectToRoute({}, 'account_upgrade');
  };

  const redirectToUpdateScientistProfile = () => {
    redirectToRoute({}, 'scientist_profile_update', {
      params: { slug: user.scientist_profile.slug },
    });
  };

  const handleScientistDialogOpen = () => {
    setScientistModal(true);
  };

  const handleScientistDialogClose = () => {
    setScientistModal(false);
  };

  const handleCreateScientistProfile = () => {
    handleScientistDialogOpen();
  };

  const signOutHandler = () => {
    setAuthCookie(null);
    Cookies.remove('token');
    router.push('/');
    // Router.reload();
  };

  const imageURL = _.get(
    _.last(_.get(linkedInUser, 'profilePicture.displayImage~.elements', '')),
    'identifiers[0].identifier',
    ''
  );

  const getHomeHref = () => {
    let route = '/';
    if (auth && user) {
      if (user.selected_type && user.selected_type === 'organiser') {
        route = 'my_opportunities';
      } else {
        route = 'opportunity_search';
      }
    }
    return route;
  };

  if (refetchScientist && user.scientist_profile) {
    redirectToRoute({}, 'scientist_profile_update', {
      params: { slug: user.scientist_profile.slug },
    });
  }

  const handleSuccessCreateScientistProfile = () => {
    handleScientistDialogClose();
    refetch();
    setRefetchSCientist(true);
  };

  const [
    createScientistProfileMutation,
    {
      loading: scientistProfileMutationLoading,
      error: scientistProfileMutationError,
      data: scientistProfileMutationData,
    },
  ] = useMutation(CREATE_SCIENTIST_PROFILE, {
    onCompleted: handleSuccessCreateScientistProfile,
  });

  const createScientistProfileHandler = () => {
    const { ...input } = scientistProfile;
    createScientistProfileMutation({
      variables: {
        input,
      },
    });
  };

  const { userInbox } = useStateContext();

  return (
    <>
      <MutationsStatus
        loading={scientistProfileMutationLoading}
        error={scientistProfileMutationError}
        success={scientistProfileMutationData}
      />
      {/* <NoSsr>{showCookieAlert && <CookieAlert closePopup={closePopupHandler} />}</NoSsr> */}
      <AppBar
        sx={{
          backgroundColor: router.pathname === '/index' ? '#236B81 !important' : 'white !important',
        }}
        position="fixed"
        haselevation={hasElevation ? 1 : 0}
      >
        <Toolbar>
          {sidebar && (
            <IconButton
              sx={{ mr: 1 }}
              color="inherit"
              aria-label="open drawer"
              onClick={onClick}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Spacer width="15px" height="10px" />
          <LogoContainer>
            {router.pathname === '/index' ? (
              <Link href={getHomeHref()} color="inherit" title="home page">
                <Image
                  bottom="0.3125rem"
                  //  width="160px"
                  height="39.8px"
                  src={Logo}
                  alt="SciVenia"
                />
                {/* <Image bottom="0.3125rem" width="143px" src={Logo}  /> */}
                {/* <Typography variant="h6">SciVenia</Typography> */}
              </Link>
            ) : (
              <Link href={getHomeHref()} color="inherit" title="home page">
                <Image
                  bottom="0.3125rem"
                  // width="160px"
                  height="39.8px"
                  src={isMobile? SmallLogoAfterLogin : LogoAfterLogin}
                  alt="SciVenia"
                />
                {/* <Image bottom="0.3125rem" width="143px" src={Logo}  /> */}
                {/* <Typography variant="h6">SciVenia</Typography> */}
              </Link>
            )}
            {/* <SearchIconBox>
              {' '}
              <SearchIcon fontSize="small"/>
            </SearchIconBox> */}
          </LogoContainer>
          <>
            {/* <LocaleSelect id="header-language-menu" /> */}
            {linkedInUser.localizedFirstName ? (
              <div>
                {t('landing:welcome_back')},
                {`${linkedInUser.localizedFirstName} ${linkedInUser.localizedLastName}`}
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  {linkedInUser.localizedLastName ? (
                    <UserAvatar width="30px" src={imageURL} />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
                <IconButton>
                  <KeyboardArrowDownIcon onClick={handleMenu} />
                </IconButton>
                <IconButton>
                  <NotificationsIcon />
                </IconButton>
                {/* <UserButton
                // variant="outlined"
                variant="text"
                color="inherit"
                onClick={handleMenu}
              >
                {' '}
                <UserAvatar color="primary" width="40px" src={user.avatar.thumb} /> {user.name}{' '}
              </UserButton> */}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                  style={{ paddingTop: 0, paddingBottom: 0, top: '35px', left: 0, right: '20px' }}
                >
                  <MenuItem onClick={redirectToProfile}>{t('common:account')}</MenuItem>

                  <MenuItem onClick={signOutHandler}>
                    <Link href="/" color="inherit">
                      {t('common:logout')}
                    </Link>
                  </MenuItem>
                  {!isOrganiser(user) && (
                    <MenuItem
                      style={{ borderTop: '1px solid lightgray' }}
                      disabled={
                        (upgradeStatus && upgradeStatus.status === 'waiting') ||
                        upgradeStatus === 'organizer'
                      }
                      onClick={redirectToUpgradeAcount}
                    >
                      {t('common:upgrade_account')}
                    </MenuItem>
                  )}
                </Menu>
              </div>
            ) : (
              <>
                {auth && user ? (
                  <Box display="flex" alignItems="center">
                    <Spacer width="10px" height="10px" />
                    <NextLink href="/inbox" passHref>
                      <IconButton aria-label="User inbox">
                        <Badge
                          color="secondary"
                          badgeContent={userInbox.globalUnreadCount}
                          invisible={false}
                        >
                          <MessageOutlined />
                        </Badge>
                      </IconButton>
                    </NextLink>

                    <Spacer width="10px" height="10px" />
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      {user.avatar ? (
                        <UserAvatar width="30px" src={user.avatar.thumb} />
                      ) : (
                        <AccountCircle />
                      )}
                    </IconButton>
                    <Hidden smDown>
                      <Typography variant="body1" color="black" fontWeight={400}>
                        {user.name}
                      </Typography>
                    </Hidden>
                    {/* <Spacer width="10px" height="10px" /> */}
                    <IconButton>
                      <KeyboardArrowDownIcon onClick={handleMenu} />
                    </IconButton>
                    <Spacer width="10px" height="10px" />
                    {/* <IconButton>
                      <NotificationsIcon />
                    </IconButton> */}
                    <Spacer width="10px" height="10px" />
                    {/* <UserButton
                    // variant="outlined"
                    variant="text"
                    color="inherit"
                    onClick={handleMenu}
                  >
                    {' '}
                    <UserAvatar color="primary" width="40px" src={user.avatar.thumb} /> {user.name}{' '}
                  </UserButton> */}
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={handleClose}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        top: '35px',
                        left: 0,
                        right: '20px',
                      }}
                    >
                      <MenuItem onClick={redirectToProfile}>
                        {/* <AccountCircle sx={{ mr: 2 }} color='secondary' fontSize='small' /> */}
                        {t('common:account')}
                      </MenuItem>
                      {user.is_scientist && (
                        <MenuItem sx={{ pr: 10 }} onClick={redirectToUpdateScientistProfile}>
                          {/* <PersonPinIcon sx={{ mr: 2 }} color='secondary' fontSize='small' /> */}
                          {t('landing:sci_profile')}
                        </MenuItem>
                      )}

                      <MenuItem onClick={signOutHandler}>
                        {/* <ExitToAppOutlinedIcon sx={{ mr: 2 }} color='secondary' fontSize='small' /> */}
                        <Link href="/" color="inherit">
                          {t('common:logout')}
                        </Link>
                      </MenuItem>
                      {!isOrganiser(user) && (
                        <MenuItem
                          style={{ borderTop: '1px solid lightgray' }}
                          disabled={
                            (upgradeStatus && upgradeStatus.status === 'waiting') ||
                            upgradeStatus === 'organizer'
                          }
                          onClick={redirectToUpgradeAcount}
                        >
                          <div>
                            {/* <AccountCircle /> */}
                            {t('common:upgrade_account')}
                          </div>
                        </MenuItem>
                      )}
                      {!user.is_scientist && (
                        <MenuItem
                          style={{ borderTop: '1px solid lightgray' }}
                          onClick={handleCreateScientistProfile}
                        >
                          <div>
                            {/* <AccountCircle /> */}
                            {t('landing:create_profile')}
                          </div>
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                ) : (
                  <>
                    <Link href="login" id="login" color="inherit">
                      <MuiButton
                        style={{ fontFamily: 'Roboto' }}
                        sx={{ mr: 1, color: router.pathname === '/index' ? 'white' : 'black' }}
                        color="inherit"
                      >
                        {t('login')}
                      </MuiButton>
                    </Link>
                    <Grid justifyContent="center" alignItems="center">
                      <MyDivider orientation="vertical" flexItem />
                    </Grid>
                    <Link href="register" id="register" color="inherit">
                      <MuiButton
                        style={{ fontFamily: 'Roboto' }}
                        sx={{ ml: 1, color: router.pathname === '/index' ? 'white' : 'black' }}
                        color="inherit"
                      >
                        {t('register')}
                      </MuiButton>
                    </Link>
                  </>
                )}
              </>
            )}
          </>
        </Toolbar>
      </AppBar>

      <CustomDialog
        open={scientistModal}
        onClose={handleScientistDialogClose}
        title={t('howItWorks:create_profile')}
        cancelButtonText={t('common:cancel')}
        acceptButtonText={t('common:yes')}
        loading={scientistProfileMutationLoading}
        handleConfirm={createScientistProfileHandler}
      >
        {t('want_create')}
      </CustomDialog>
    </>
  );
};

Header.propTypes = {
  sidebar: PropTypes.bool,
  onClick: PropTypes.func,
  hasElevation: PropTypes.bool,
  linkedInUser: PropTypes.object,
};

Header.defaultProps = {
  sidebar: false,
  hasElevation: true,
  onClick: () => {},
  linkedInUser: {},
};

export default Header;
