/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import RoomIcon from '@mui/icons-material/Room';
import StyleIcon from '@mui/icons-material/Style';
import LanguageIcon from '@mui/icons-material/Language';
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EventIcon from '@mui/icons-material/Event';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import Image from 'next/image';
import { 
  // useQuery, 
  useMutation, 
  gql 
} from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  CardActionArea,
  Tooltip,
  Grid,
  Box,
  Zoom,
} from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Typography from '@atoms/Typography';
import Menu from '@molecules/Menu';
import Link from '@atoms/Link';
import { EVENT_TYPES } from '@app/config/utils';
import { redirectToRoute } from '@app/helpers';
import MutationsStatus from '@molecules/MutationsStatus';
import { DELETE_OPPORTUNITY, LIKE_OPPORTUNITY } from 'apollo/mutations';
import CustomDialog from '@app/components/molecules/CustomDialog';
// import { OPPORTUNITY_FEEDBACK_FORM } from '@app/apollo/queries';
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { useTranslation, i18n } from '../../../../config/i18n';
import {
  CardContent,
  ContentContainer,
  CardHeader,
  Card,
  CardLabel,
  useClasses,
  OrganisationAvatar,
  MetaItem,
  MetaItemDesc,
  OpportunityDescription,
  StyledLink,
  CardSublabel,
  ShareButton,
  StyledPopover,
  CopyCliboard,
} from './style';

const cardLogo = '/images/home/card_logo.png';

const OpportunityCard = ({
  id,
  slug,
  title,
  // city,
  location,
  logo,
  deadline_date,
  organisation,
  start_date,
  is_virtual,
  end_date,
  description,
  is_paid,
  // genres,
  summary,
  event_types,
  is_external,
  user,
  userInfo,
  onClick,
  event_type,
  // responsive_set,
  // placeholder,
  loading,
  handleRefresh,
  feedback,
  likes,
  feedback_form
}) => {
  const { t } = useTranslation(['dashboard', 'applications', 'wizard', 'organisations']);

  let eventTypeHaveReview;
  let eventTypeHaveSubmission;
  let eventTypeHaveTracks;
  let eventTypeHaveCommittees;
  if (!loading) {
    eventTypeHaveReview =
      event_type.slug === EVENT_TYPES.CONFERENCE_MULTI_TRACK ||
      event_type.slug === EVENT_TYPES.CONFERENCE_SINGLE_TRACK ||
      event_type.slug === EVENT_TYPES.WORKSHOP ||
      event_type.slug === EVENT_TYPES.SYMPOSIUM ||
      event_type.slug === EVENT_TYPES.COMPETITIONS ||
      event_type.slug === EVENT_TYPES.SCIENCE_EXHIBITIONS_AND_SCIENCE_FESTIVAL;

    eventTypeHaveSubmission =
      event_type.slug === EVENT_TYPES.CONFERENCE_MULTI_TRACK ||
      event_type.slug === EVENT_TYPES.CONFERENCE_SINGLE_TRACK ||
      event_type.slug === EVENT_TYPES.WORKSHOP ||
      event_type.slug === EVENT_TYPES.SYMPOSIUM;

    eventTypeHaveTracks = event_type.slug === EVENT_TYPES.CONFERENCE_MULTI_TRACK;

    eventTypeHaveCommittees =
      event_type.slug === EVENT_TYPES.CONFERENCE_MULTI_TRACK ||
      event_type.slug === EVENT_TYPES.CONFERENCE_SINGLE_TRACK ||
      event_type.slug === EVENT_TYPES.SYMPOSIUM ||
      event_type.slug === EVENT_TYPES.WORKSHOP;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPopover, setPopOver] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const userLiked = (oppLikes, userId) => oppLikes?.some(
    like => like?.user?.id === userId
  );

  const [likesCount, setLikesCount] = useState(likes?.length || 0);
  const [isUserLiked, setIsUserLiked] = useState(userLiked(likes, userInfo?.id));

  // const { loading: opportunityFeedbackFormLoading, data: opportunityFeedbackFormData, refetch } = useQuery(OPPORTUNITY_FEEDBACK_FORM, {
  //   variables: {
  //     opportunity: id,
  //   },
  // });

  // useEffect(() => {
  //   if (feedback) {
  //     refetch();
  //   }
  // }, [feedback])

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleOpportunityManage = () => {
  //   setAnchorEl(null);
  //   redirectToRoute({}, 'opportunity_dashboard', { params: { slug } });
  // };

  const handleViewOpportunity = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'view_opportunity', { params: { slug } });
  };

  const handleUpdateOpportunity = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'edit_event', { params: { slug } });
  };

  const handleCreateFeedbackForm = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'event_feedback', { params: { slug } });
  };

  function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      // eslint-disable-next-line no-return-await
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSuccessDeletion = () => {
    handleRefresh();
    handleCloseDialog();
  };

  const handleSuccessLikes = () => {
    handleRefresh();
  };

  const [
    deleteOpportunityMutation,
    { loading: deleteMutationLoading, error: deleteMutationError, data: deleteMutationData },
  ] = useMutation(DELETE_OPPORTUNITY, {
    onCompleted: handleSuccessDeletion,
  });

  const [
    likesMutation,
  ] = useMutation(LIKE_OPPORTUNITY, {
    onCompleted: handleSuccessLikes,
  });

  const handleDeleteEvent = () => {
    setAnchorEl(null);
    deleteOpportunityMutation({
      variables: {
        id,
      },
    });
  };

  const handleOpenPopOver = (event) => {
    setPopOver(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopOver(null);
  };

  const classes = useClasses();

  const handleLike = () => {
    setAnchorEl(null);
    if (isUserLiked) {
      setLikesCount(likesCount - 1);
    } else {
      setLikesCount(likesCount + 1);
    }
    setIsUserLiked(!isUserLiked);
    likesMutation({
      variables: {
        opportunityId: id,
      },
    });
  }

  return (
    <>
      <MutationsStatus
        loading={deleteMutationLoading}
        error={deleteMutationError}
        success={deleteMutationData}
      />
      <Card variant="elevation">
        <CardActionArea>
          {loading ? (
            <Skeleton animation="wave" variant="rect" height={136} />
          ) : (
            <div
              aria-hidden="true"
              className={classes.mediaContainer}
              onClick={onClick}
              onKeyDown={onClick}
            >
              <div className={classes.image}>
                <Image
                  // className={classes.image}
                  src={logo.path}
                  alt={title}
                  // layout="intrinsic" height="250px" width="443px"
                  onClick={onClick}
                  layout="fill"
                  objectFit="cover"
                />
                {/* <CardMedia
                // component={Image}
                component="img"
                srcSet={responsiveSet}
                sizes="300px"
                // sizes={`${devices.xs} 300px, ${devices.sm} 400px, ${devices.md} 300px`}
                // sizes="50vw"
                src={logo.path}
                title={logo.name}
                onClick={onClick}
                /> */}
              </div>
              <div className={`${classes.additionalInfo} additionalInfo`}>
                <div className={classes.details}>
                  <OpportunityDescription variant="body1">
                    <Typography
                      variant="body2"
                      color="white"
                      gutterBottom
                      dangerouslySetInnerHTML={{ 
                        __html: description !== '<p><br></p>' && description !== null ? (description.length > 50  ? 
                            `${description.replace(/<(h[1-6]|strong|em|br)/g, '<$1span').substring(0, 51)  }...`
                          : 
                            description.replace(/<(h[1-6]|strong|em|br)/g, '<$1span')
                        ) : (
                          t('common:No Description')
                        )
                      }}
                    />
                  </OpportunityDescription>
                  {/* {deadline_date && (
                    <Box display="flex" alignItems="center ">
                      <AccessAlarmIcon style={{ marginRight: '10px' }} />{' '}
                      <Typography weight="700" variant="overline">
                        {moment(deadline_date).format('DD MMM YYYY')}
                      </Typography>
                    </Box>
                  )} */}
                  {organisation && (
                    <StyledLink
                      href="organisation_view"
                      params={{ slug: organisation.slug }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Typography variant="overline"> {organisation.name}</Typography>{' '}
                    </StyledLink>
                  )}
                </div>
                <div className={classes.stats}>{is_paid && <MonetizationOnOutlinedIcon />}</div>
              </div>
              {/* </CardMedia> */}
            </div>
          )}
        </CardActionArea>
        <CardContent>
          {
          // feedback && opportunityFeedbackFormLoading ? (
          //   <Box width="100%" pt={0.5}>
          //     <Skeleton />
          //     <Skeleton width="60%" />
          //   </Box>
          // ) : 
          loading ? (
            <Box width="100%" pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          ) : (
            <>
              {organisation ? (
                <Link href="organisation_view" params={{ slug: organisation && organisation.slug }}>
                  <OrganisationAvatar
                    src={organisation ? organisation.logo.thumb : cardLogo}
                    elevation={2}
                    width="78.96px"
                  />
                </Link>
              ) : (
                <OrganisationAvatar
                  src={organisation ? organisation.logo.thumb : cardLogo}
                  elevation={2}
                  width="78.96px"
                />
              )}

              <ContentContainer>
                <Typography 
                  color="#757575" 
                  variant="overline"
                  style={{
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    letterSpacing: '0em',
                    marginTop: '10px'
                  }}
                >
                  {start_date
                    ? `${moment(start_date).format('DD MMM')} ${
                        end_date ? `- ${moment(end_date).format('D MMM  YYYY')}` : ''
                      }`
                    : `${t('wizard:ongoing')}`}
                </Typography>

                <CardHeader
                  action={
                    userInfo &&
                    user &&
                    user.id === userInfo.id && (
                      <IconButton
                        aria-label="settings"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    )
                  }

                  title={
                    title.length > 50 ? (
                      <Tooltip
                        title={title}
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                        // enterDelay={300}
                        leaveDelay={500}
                      >
                        <div>
                          <CardLabel
                            style={{ marginBottom: '10px' }}
                            variant="h5"
                            // lineHeight={32}
                            // maxLines={2}
                            // onClick={onClick}
                          >
                            {title.substring(0, 51)}...
                          </CardLabel>
                        </div>
                      </Tooltip>
                    ) : (
                      <CardLabel
                        style={{ marginBottom: '10px' }}
                        variant="h5"
                        // lineHeight={32}
                        // maxLines={2}
                        // onClick={onClick}
                      >
                        {title}
                      </CardLabel>
                    )
                  }

                  subheader={
                    <Grid item xs={12}>
                      {/* <Tooltip title={ description !== '<p><br></p>' && description !== null &&
                        <span
                          dangerouslySetInnerHTML={{
                            __html: description
                          }}
                        />
                      }> */}
                        <MetaItemDesc>
                          <Typography
                            variant="body2"
                            dangerouslySetInnerHTML={{ 
                              __html: description !== '<p><br></p>' && description !== null ? (description.length > 70  ? 
                                  `${description.replace(/<(h[1-6]|strong|em|br)/g, '<$1span').substring(0, 71)  }...`
                                : 
                                  description.replace(/<(h[1-6]|strong|em|br)/g, '<$1span')
                              ) : (
                                t('common:No Description')
                              )
                            }}
                          />
                        </MetaItemDesc>
                      {/* </Tooltip> */}

                      {is_external ? (
                        <Tooltip title={event_type.name}>
                          <MetaItem>
                            <EventIcon fontSize="small" />
                            <CardSublabel variant="caption">
                            {t('common:External Event')}
                            </CardSublabel>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <MetaItem>
                          <EventIcon fontSize="small" />
                          <CardSublabel variant="caption">
                            {t('common:Internal Event')}
                          </CardSublabel>
                        </MetaItem>
                      )}

                      {event_type ? (
                        <Tooltip title={event_type.name}>
                          <MetaItem>
                            <StyleIcon fontSize="small" />
                            <CardSublabel variant="caption">
                              {event_type.name}
                            </CardSublabel>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <MetaItem>
                          <StyleIcon fontSize="small" />
                          <CardSublabel variant="caption">
                            {t('common:No Event Type')}
                          </CardSublabel>
                        </MetaItem>
                      )}

                      {is_virtual || (location && location.formatted_address) ? (
                        <Tooltip
                          title={
                            is_virtual
                              ? t('common:virtual_opportunity')
                              : location && location.formatted_address
                          }
                        >
                          <MetaItem>
                            {is_virtual ? (
                              <LanguageIcon fontSize="small" />
                            ) : (
                              <RoomIcon fontSize="small" />
                            )}
                            <CardSublabel variant="caption">
                              {is_virtual
                                ? t('common:virtual_opportunity')
                                : location && location.formatted_address}
                            </CardSublabel>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <MetaItem />
                      )}

                      <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={3}>
                        <button
                          onClick={onClick}
                          style={{
                            // marginTop: '25px',
                            cursor: 'pointer',
                            width: '175px',
                            height: '36px',
                            padding: '10px 30px',
                            borderRadius: '100px',
                            gap: '8px',
                            color: 'white',
                            background: '#0A1744',
                            boxShadow: '0px 4px 4px 0px #00000014',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onMouseOver={(e) => { e.target.style.textDecoration = 'underline'; }}
                          onMouseOut={(e) => { e.target.style.textDecoration = 'none'; }}
                        >
                          {t('common:Open Event')}
                          <ArrowRightAltIcon />
                        </button>

                        {userInfo && 
                          <box
                            onClick={handleLike}
                            style={{
                              cursor: 'pointer',
                              width: '40px',
                              height: '36px',
                              borderRadius: '50%',
                              // background: '#0A1744',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {isUserLiked ?
                              <FavoriteIcon style={{ color: '#0A1744' }}  /> : 
                              <FavoriteBorderIcon style={{ color: '#0A1744' }}  />
                            }
                            <Typography variant="body2" color="primary">
                              {likesCount}
                            </Typography>
                          </box>
                        }
                      </Box>
                    </Grid>
                  }
                />
              </ContentContainer>
            </>
          )}
        </CardContent>

        {/* <CardActions><Button color="primary" size="small">Learn More</Button></CardActions> */}
      </Card>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Menu.Item onClick={handleOpenPopOver}>
          <ShareButton
            startIcon={<ShareIcon />}
            variant="outlined"
            color="primary"
            aria-describedby="share-opportunity"
          >
            {t('opportunity:share')}
          </ShareButton>
        </Menu.Item>
        {/* <Menu.Item onClick={handleOpportunityManage}>{t('manage_opp')}</Menu.Item> */}
        <Menu.Item onClick={handleViewOpportunity}>{t('view_opp')}</Menu.Item>
        <Menu.Item onClick={handleUpdateOpportunity}>{t('edit_opp_info')}</Menu.Item>
        <Menu.Item onClick={handleCreateFeedbackForm}>{!feedback_form ? t('Create feedback') : t('Update feedback')}</Menu.Item>
        <Menu.Item onClick={handleOpenDialog}>{t('delete_opp')}</Menu.Item>
      </Menu>

      <StyledPopover
        id="share-opportunity"
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InstapaperShareButton
            style={{ display: 'flex', alignItems: 'center' }}
            url={`${process.env.NEXT_PUBLIC_REDIRECT_URI}/${i18n.language}/event/${slug}`}
          >
            <InstagramIcon color="primary" />
          </InstapaperShareButton>
          <TwitterShareButton
            style={{ display: 'flex', alignItems: 'center' }}
            url={`${process.env.NEXT_PUBLIC_REDIRECT_URI}/${i18n.language}/event/${slug}`}
          >
            <TwitterIcon color="primary" />
          </TwitterShareButton>

          <FacebookShareButton
            style={{ display: 'flex', alignItems: 'center' }}
            url={`${process.env.NEXT_PUBLIC_REDIRECT_URI}/${i18n.language}/event/${slug}`}
          >
            {' '}
            <FacebookIcon color="primary" />
          </FacebookShareButton>
          <LinkedinShareButton
            style={{ display: 'flex', alignItems: 'center' }}
            url={`${process.env.NEXT_PUBLIC_REDIRECT_URI}/${i18n.language}/event/${slug}`}
          >
            <LinkedInIcon color="primary" />
          </LinkedinShareButton>
          <CopyCliboard>
            {' '}
            <LinkIcon
              color="primary"
              onClick={() =>
                handleCopyClick(
                  `${process.env.NEXT_PUBLIC_REDIRECT_URI}/${i18n.language}/event/${slug}`
                )
              }
            />
            <Typography variant="caption" color="primary">
              {isCopied ? 'Copied!' : null}
            </Typography>
          </CopyCliboard>
        </Box>
      </StyledPopover>

      <CustomDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={`${t('common:delete')} ${t('common:opportunity')}`}
        cancelButtonText={t('common:cancel')}
        acceptButtonText={t('common:delete')}
        loading={deleteMutationLoading}
        handleConfirm={handleDeleteEvent}
      >
        {t('applications:delete_opportunity_message')}
      </CustomDialog>
    </>
  );
};

OpportunityCard.propTypes = {
  title: PropTypes.string,
  event_type: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string,
  location: PropTypes.object,
  logo: PropTypes.object,
  deadline_date: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  is_virtual: PropTypes.bool,
  event_types: PropTypes.array,
  user: PropTypes.object,
  organisation: PropTypes.object,
  description: PropTypes.string.isRequired,
  is_paid: PropTypes.bool.isRequired,
  summary: PropTypes.string.isRequired,
  handleRefresh: PropTypes.func,
  feedback: PropTypes.bool.isRequired,
  is_external: PropTypes.bool,
  likes: PropTypes.array,
  feedback_form: PropTypes.object,
};

OpportunityCard.defaultProps = {
  userInfo: undefined,
  loading: false,
  onClick: undefined,
  title: undefined,
  is_virtual: false,
  // id: '',
  slug: '',
  location: undefined,
  logo: undefined,

  deadline_date: '',
  start_date: '',
  end_date: '',
  event_types: [],
  user: undefined,
  organisation: undefined,
  is_external: false,
  handleRefresh: () => {},
  likes: [],
  feedback_form: null
};

OpportunityCard.fragments = {
  opportunity: gql`
    fragment OpportunityCard on Opportunity {
      id
      title
      slug
      description
      summary
      location {
        formatted_address
      }
      logo {
        path
      }
      organisation {
        name
        slug
        logo {
          thumb
        }
      }
      is_paid
      deadline_date
      start_date
      is_external
      is_virtual
      event_types {
        id
        name
      }
      event_type {
        name
        slug
      }
      user {
        id
      }
      views {
        id
      }
      likes {
        id
        user {
          id
          name
          email
        }
      }
      feedback_form {
        id
        # fields {
        #   id
        #   label
        #   type
        #   order
        #   subtype
        #   is_required
        #   choices
        #   child_fields {
        #     id
        #     label
        #     slug
        #     type
        #   }
        # }
        # opportunity {
        #   id
        # }
        # supported_languages
        # is_active
        # is_skipped
        # max_members
      }
    }
  `,
};

export default OpportunityCard;
