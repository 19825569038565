import styled from 'styled-components';
import { AppBar as MuAppBar, Box, Divider } from '@mui/material';
import Button from '../../../atoms/Button';
import Avatar from '../../../atoms/Avatar';

export const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const SearchIconBox = styled(Box)`
 && {
 background-color: ${({ theme }) => theme.red[300]};
     margin-left: 20px; 
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
`;

export const AppBar = styled(MuAppBar)`
  && {
    ${({ haselevation }) => !haselevation && `box-shadow: none;`}
    background-color: transparent;
    /* background-color: #236B81 !important; */
    color: #000;
    z-index: 3;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  css-1d4goo2-MuiPaper-root-MuiAppBar-root {
    background-color: #fff !important;
    color: #000 !important;
  }
  
`;

export const UserButton = styled(Button)`
  && {
    border-radius: 25px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: ${({ theme }) => theme && theme.red[400]};
  }
`;

export const UserAvatar = styled(Avatar)`
  && {
    padding: 0;
    // margin-right: 10px;
  }
`;

export const MyDivider = styled(Divider)`
  && {
    height: 10px;
    background-color: white;
  }
`

