import React from 'react';
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RoundedButton from '@app/components/atoms/RoundedButton';
import Spacer from '@app/components/atoms/Spacer';


const CustomDialog = ({ open, onClose, title, children, cancelButtonText, acceptButtonText, loading, handleConfirm, maxWidth, fullWidth, disabled, TransitionComponent, fullScreen, disableBackdropClick, paperProps }) => (
    <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="custom-dialog-title"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        disableBackdropClick={disableBackdropClick}
        TransitionComponent={TransitionComponent}
        PaperProps={{
            style: {
                borderRadius: '16px',
                ...paperProps?.style,
            },
            ...paperProps,
        }}
    >
        {title && (
            <DialogTitle id="custom-dialog-title" style={{ backgroundColor: '#f0f0f0' }}>
                {title}
            </DialogTitle>
        )}
        <Spacer height='0.1rem' />
        <DialogContent 
            style={{ padding: '16px 1.5rem' }}
        >
            {children}
        </DialogContent>
        {(cancelButtonText || acceptButtonText) && (
            <>
                <DialogActions style={{ paddingRight: '1.5rem' }}>
                    {cancelButtonText && (
                        <RoundedButton onClick={onClose} color="primary" variant="outlined">
                            {cancelButtonText}
                        </RoundedButton>
                    )}
                    <Spacer width='0.5rem' />
                    {acceptButtonText && (
                        <RoundedButton
                            loading={loading}
                            disabled={disabled}
                            variant="contained"
                            onClick={handleConfirm}
                            color="primary"
                            autoFocus
                        >
                            {acceptButtonText}
                        </RoundedButton>
                    )}
                </DialogActions>
                <Spacer height='1rem' />
            </>
        )}
    </Dialog>
);

CustomDialog.propTypes = {
    title: PropTypes.string,
    cancelButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    maxWidth: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    TransitionComponent: PropTypes.elementType,
    fullScreen: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
    paperProps: PropTypes.object,
}

CustomDialog.defaultProps = {
    open: false,
    title: '',
    cancelButtonText: '',
    acceptButtonText: '',
    loading: false,
    maxWidth: '',
    fullWidth: false,
    disabled: false,
    TransitionComponent: undefined,
    handleConfirm: ()=>{},
    onClose: ()=>{},
    fullScreen: false,
    disableBackdropClick: false,
    paperProps: {},
}

export default CustomDialog;