/* eslint-disable guard-for-in */
/* eslint-disable func-names */

const applicationsRoutes = {
  my_applications: {
    url: '/applications/submissions',
    component: '/applications/list',
  },
  my_registrations: {
    url: '/applications/registrations',
    component: '/applications/registrations',
  },
  // my_application_view: {
  //   url: '/application/:id',
  //   component: '/applications/view',
  // },
  my_submission_application_view: {
    url: '/submission-application/:id',
    component: '/applications/view',
  },
  my_registration_application_view: {
    url: '/registration-application/:id',
    component: '/applications/view-registration',
  },
  submission_application_complete: {
    url: '/application/:id/submission-complete',
    component: '/applications/submission-complete',
  },
  registration_application_complete: {
    url: '/application/:id/registration-complete',
    component: '/applications/registration-complete',
  },
  review_application_complete: {
    url: '/application/:id/review-complete',
    component: '/applications/review-complete',
  },
  application_review: {
    url: '/application/:id/review',
    component: '/applications/review',
  },
  application_edit: {
    url: '/application/:id/edit',
    component: '/applications/edit',
  },
  // organizer_registration_view: {
  //   url: '/opportunity/:slug/registrations/:id',
  //   component: '/applications/view-registrations'
  // },
  // review_submission: {
  //   url: '/opportunity/:slug/reviews/:id',
  //   component: '/applications/review-submission'
  // },
};

const organisationRoutes = {
  organisation_create: {
    url: '/organisations/create',
    component: '/organisations/create',
  },
  organisation_update: {
    url: '/organisation/:slug/update',
    component: '/organisations/update',
  },
  setup_payment: {
    url: '/organisation/:slug/payment/connect',
    component: '/organisations/setup-payment',
  },
  payment_waiting: {
    url: '/organisation/connect',
    component: '/organisations/payment-waiting',
  },
  organisation_view: {
    url: '/organisation/:slug',
    component: '/organisations/profile',
  },
  organisation_dashboard: {
    url: '/organisation/:slug/dashboard',
    component: '/organisations/dashboard',
  },
  organisation_opportunities: {
    url: '/organisation/:slug/opportunities',
    component: '/organisations/opportunities',
  },
};

const personalRoutes = {
  update_profile: {
    url: '/profile/update',
    component: '/auth/update-profile',
  },
  connect_user_payments: {
    url: '/profile/connect-user-payments',
    component: '/auth/user-payments-connect',
  },
  user_payments_setup: {
    url: '/profile/setup-payments',
    component: '/auth/user-payments-setup',
  },
  scientist_profile_update: {
    url: '/scientist-profile/:slug/update',
    component: '/auth/scientist-profile-update',
  },
  my_opportunities: {
    url: '/events',
    component: '/events',
  },
  review_opportunities: {
    url: '/events/review',
    component: '/opportunities/review',
  },
  my_organisations: {
    url: '/organisations',
    component: '/organisations/my-organisations',
  },
  wizard_home: {
    url: '/event/wizard',
    component: '/opportunity-wizard',
  },
  // opportunity_dashboard: {
  //   url: '/event/:slug/dashboard',
  //   component: '/opportunities/dashboard',
  // },
  event_type: {
    url: '/event/event-type',
    component: '/event-type',
  },
  organisations_search: {
    url: '/organisation/search',
    component: '/opportunities/search_organisations',
  },
  scientists_search: {
    url: '/scientist/search',
    component: '/opportunities/search_scientists',
  },
  opportunity_payment: {
    url: '/event/:slug/payment',
    component: '/opportunities/payment-info',
  },
  opportunity_payments_details: {
    url: '/event/:slug/payments/details',
    component: '/opportunities/payments-details',
  },
  account_upgrade: {
    url: '/account/upgrade',
    component: '/auth/upgrade-account',
  },
  view_scientist: {
    url: '/scientist-profile/:slug',
    component: '/auth/scientist-profile-view',
  },
  update_opportunity: {
    url: '/event/:slug/update',
    component: '/opportunities/update',
  },
  edit_event: {
    url: '/event/:slug/edit',
    component: '/opportunities/edit',
  },
  event_feedback: {
    url: '/event/:slug/feedback',
    component: '/opportunities/feedback',
  },
  give_feedback: {
    url: '/event/:slug/give-feedback',
    component: '/opportunities/give-feedback',
  },
  view_registration: {
    url: '/event/:slug/registrations/:id',
    component: '/opportunities/view-registration',
  },
  view_submission: {
    url: '/event/:slug/submissions/:id',
    component: '/opportunities/view-submission',
  },
  view_review: {
    url: '/event/:slug/reviews/:id',
    component: '/opportunities/view-review',
  },
  update_review: {
    url: '/event/:slug/reviews/:id/update',
    component: '/opportunities/update-review',
  },
  review_submission: {
    url: '/event/:slug/reviews/:id/review',
    component: '/opportunities/review-submission',
  },
  opportunity_program_agenda_create_session: {
    url: '/event/:eventSlug/program-agenda/create',
    component: '/opportunities/program-agenda/form',
  },
  opportunity_program_agenda_session_details: {
    url: '/event/:eventSlug/program-agenda/:sessionSlug/details',
    component: '/opportunities/program-agenda/details',
  },
  opportunity_program_agenda_session_edit: {
    url: '/event/:eventSlug/program-agenda/:sessionSlug/edit',
    component: '/opportunities/program-agenda/form',
  },
  user_inbox: {
    url: '/user/inbox',
    component: '/inbox',
  },
};

const routes = {
  homepage: {
    url: '/',
    component: '/index',
  },
  login: {
    url: '/login',
    component: '/auth/login',
  },
  register: {
    url: '/register',
    component: '/auth/register',
  },
  register_complete: {
    url: '/register/complete',
    component: '/auth/register-complete',
  },
  pass_forgot: {
    url: '/password/forgot',
    component: '/auth/forgot-password',
  },
  pass_reset: {
    url: '/password/reset',
    component: '/auth/update-password',
  },
  scientist_profile_view: {
    url: '/scientist-profile/:slug',
    component: '/auth/scientist-profile-view',
  },
  verify_account: {
    url: '/verify',
    component: '/auth/verify',
  },
  application_payment: {
    url: '/application/:id/pay',
    component: '/applications/pay',
  },
  application_complete: {
    url: '/application/:id/complete',
    component: '/applications/complete',
  },
  opportunity_search: {
    url: '/event/search',
    component: '/opportunities/search',
  },
  view_opportunity: {
    url: '/event/:slug',
    component: '/opportunities/view',
  },
  opportunity_sponsor: {
    url: '/event/:slug/sponsors',
    component: '/opportunities/sponsors',
  },
  opportunity_committee: {
    url: '/event/:slug/committees',
    component: '/opportunities/committees',
  },
  opportunity_practical_info: {
    url: '/event/:slug/practical-info',
    component: '/opportunities/practical-info',
  },
  opportunity_submissions: {
    url: '/event/:slug/submissions',
    component: '/opportunities/submissions',
  },
  opportunity_reviews: {
    url: '/event/:slug/reviews',
    component: '/opportunities/reviews',
  },
  opportunity_registrations: {
    url: '/event/:slug/registrations',
    component: '/opportunities/registrations',
  },
  opportunity_feedbacks: {
    url: '/event/:slug/feedbacks',
    component: '/opportunities/feedbacks',
  },
  opportunity_program_agenda: {
    url: '/event/:slug/program-agenda',
    component: '/opportunities/program-agenda',
  },
  form_viewer: {
    url: '/event/:slug/apply',
    component: '/opportunities/form-viewer',
  },
  view_track: {
    url: '/event/:slug/track/:id',
    component: '/opportunities/view-track',
  },
  about_us: {
    url: '/about',
    component: '/static/about',
  },
  team_members: {
    url: '/team-members',
    component: '/static/team-members',
  },
  terms: {
    url: '/terms-conditions',
    component: '/static/terms',
  },
  contact: {
    url: '/contact',
    component: '/static/contact',
  },
  help: {
    url: '/help',
    component: '/static/help',
  },
  privacy: {
    url: '/privacy',
    component: '/static/privacy',
  },
  user_invitations: {
    url: '/committees',
    component: '/user-invitations',
  },
  travel_and_accommodation: {
    url: '/event/:slug/travel-and-accommodation',
    component: '/travel-and-accommodation',
  },
  travel_and_accommodation_details: {
    url: '/travel-and-accommodation/list',
    component: 'travel-and-accommodation/list',
  },
  organisation_account: {
    url: '/organisation/:slug/payment/account',
    component: '/organisations/account',
  },
  ...applicationsRoutes,
  ...organisationRoutes,
  ...personalRoutes,
};

const routing = function (app, nextApp) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in routes) {
    app.get(routes[key].url, (req, res) => {
      const { query, params, cookies } = req;
      const { token } = cookies;

      if ((!token || token === undefined || token === null) && (key in applicationsRoutes || key in organisationRoutes || key in personalRoutes)) {
        const preLoginUrl = req.originalUrl;
        const next = encodeURIComponent(preLoginUrl);
        return res.redirect(`/login?next=${next}`);
        // res.cookie('preLoginUrl', req.originalUrl);
        // return res.redirect('/login');
      }

      return nextApp.render(req, res, routes[key].component, { ...query, ...params });
    });
  }
};

const getRouteName = function (route) {
  const routeKey = Object.keys(routes).find((key) => routes[key].component === route);
  if (routeKey) {
    return routes[routeKey].name;
  }
  return null;
};
exports.routes = routes;
exports.routing = routing;
exports.getRouteName = getRouteName;
